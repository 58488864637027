import type { GetServerSideProps } from 'next';
import { useEffect, type ReactNode } from 'react';

import { POSTHOG_EVENT_NAME } from 'components/form/content/Posthog';
import posthog from 'posthog-js';
import HomepageContent from './HomepageContent';
import HomepageFooter from './HomepageFooter';
import HomepageHead from './HomepageHead';
import HomepageHeader from './HomepageHeader';

/**
 * @summary The basic structure of the homepage, this should stay largely untouched.
 * To add new content blocks @see HomepageContent instead.
 * @returns {JSX.Element}
 */

const Homepage = (): JSX.Element => {
  useEffect(() => {
    posthog.capture(POSTHOG_EVENT_NAME.HOMEPAGE_VISITED, { variant: 'homepage' });
  }, []);

  return (
    <>
      <HomepageHead />
      <HomepageHeader />
      <HomepageContent />
      <HomepageFooter />
    </>
  );
};

/**
 * @summary Next.js will pre-render this page on each request using the data returned by getServerSideProps
 * @see {@link https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props}
 */

export const getServerSideProps: GetServerSideProps = async ({ res }) => {
  const maxAge = 60; // 1 min
  res.setHeader('Cache-Control', `public, max-age=${maxAge}, must-revalidate, s-maxage=${maxAge}`);

  return Promise.resolve({
    props: {},
  });
};

Homepage.getLayout = (children: ReactNode) => <>{children}</>;

export default Homepage;

import { UsMonthlySignupSteps, UsSignupConfirmSteps, UsSignupS2Steps, UsSignupSteps } from './SignupSteps';

/** Common events */
export const POSTHOG_EVENT_NAME = Object.freeze({
  HOMEPAGE_VISITED: 'Homepage Visited',
  SIGNUP_STARTED: 'Signup Started',
  EMAIL_CAPTURED: 'Email Captured',
  PURCHASE_STARTED: 'purchase started',
  SENSOR_EDUCATION_CLICKED: 'sensor education link clicked',
  CONTACT_AND_SHIPPING_SUBMITTED: 'contact and shipping submitted',
  HOW_IT_WORKS_VIEWED: 'how it works page viewed',
  S1_CONTACT_AND_SHIPPING_SUBMITTED: 'S1 - contact and shipping submitted',
  S1_EMAIL_ADDED_TO_WAITLIST: 'S1 - email added to waitlist',
  S1_SURVEY_BUTTON_CLICKED: 'S1 - survey button clicked',
  S2_CGM_TYPE_SELECTED: 'S2 - cgm step next button clicked',
  S2_HOW_IT_WORKS_NEXT_CLICKED: 'S2 - how it works step next button clicked',
  S2_ELIGIBILITY_NEXT_CLICKED: 'S2 - eligibility step next button clicked',
  S2_CONTACT_AND_SHIPPING_SUBMITTED: 'S2 - contact and shipping submitted',
  S2_EMAIL_ADDED_TO_WAITLIST: 'S2 - email added to waitlist',
  S2_SURVEY_BUTTON_CLICKED: 'S2 - survey button clicked',
  S2_STUDY_EMIGIBILITY_NEXT_CLICKED: 'S2 - study eligibility next clicked',
});

/** As of Signup 3.0, we tag Posthog events by the version of the signup form. */
export const posthogSignupVersion = '3.0';

/**
 * Maps the form's step names to the appropriate Posthog event name.
 * We base Posthog event name on the name of the Figma board + the signup form version.
 * Figma: https://www.figma.com/file/HmSu6eOAplopmCQiZhBF6F/Signup-Flow---3.0?node-id=364%3A1504&t=LK2eD5Iwju4x5RVC-0
 */
export const mapSignupStepNameToPosthogEvent = (stepName: UsSignupSteps): string | null => {
  switch (stepName) {
    case UsSignupSteps.WhatsIncluded:
      return `What's Included - ${posthogSignupVersion}`;
    case UsSignupSteps.StudyEligibility:
      return `Eligibility Questions - ${posthogSignupVersion}`;
    case UsSignupSteps.ResearchStudyConsent:
      return `Study Consent - ${posthogSignupVersion}`;
    case UsSignupSteps.SetSubscription:
      return `Subscription - ${posthogSignupVersion}`;
    case UsSignupSteps.ContactAndShipping:
      return `Contact and Shipping - ${posthogSignupVersion}`;
    case UsSignupSteps.Checkout:
      return `Checkout - ${posthogSignupVersion}`;
  }
};

export const mapMonthlySignupStepNameToPosthogEvent = (stepName: UsMonthlySignupSteps): string | null => {
  switch (stepName) {
    case UsMonthlySignupSteps.AccessGateB2:
      return `Monthly Access Gate - ${posthogSignupVersion}`;
    case UsMonthlySignupSteps.SelectCgmB2:
      return `Monthly Select Cgm - ${posthogSignupVersion}`;
    case UsMonthlySignupSteps.ContactAndShippingB2:
      return `Monthly Contact and Shipping - ${posthogSignupVersion}`;
    case UsMonthlySignupSteps.CheckoutB2:
      return `Monthly Checkout - ${posthogSignupVersion}`;
  }
};

export const mapSignupS2StepNameToPosthogEvent = (stepName: UsSignupS2Steps): string | null => {
  switch (stepName) {
    case UsSignupS2Steps.HowItWorksS2:
      return `S2 How it works - ${posthogSignupVersion}`;
    case UsSignupS2Steps.ContactAndShippingS2:
      return `S2 Contact and Shipping - ${posthogSignupVersion}`;
    case UsSignupS2Steps.SelectCGMS2:
      return `S2 Select CGM - ${posthogSignupVersion}`;
    case UsSignupS2Steps.StudyEligibilityS2:
      return `S2 Study eligibility - ${posthogSignupVersion}`;
  }
};

export const mapSignupConfirmStepNameToPosthogEvent = (stepName: UsSignupConfirmSteps): string => {
  switch (stepName) {
    case UsSignupConfirmSteps.Success:
      return `Post-Purchase Success - ${posthogSignupVersion}`;
    case UsSignupConfirmSteps.Password:
      return `Post-Purchase Create Password - ${posthogSignupVersion}`;
    case UsSignupConfirmSteps.StudyEligibility:
      return `Post-Purchase Study Eligibility - ${posthogSignupVersion}`;
    default:
      return '';
  }
};

import cx from 'classnames';
import { useEffect, useState } from 'react';

import GetStartedButton from 'components/buttons/GetStartedButton';
import PrimaryButtonV2 from 'components/buttons/PrimaryButtonV2';

import { Container, Section } from 'components/homepage';

import useIsMobileAppWebview from '../../hooks/useIsMobileAppWebview';
import LogoDark from '../../public/assets/logo-dark.svg';

type HomepageHeaderProps = {
  shouldHide?: boolean;
};

const headerTemplate = () => {
  return (
    // TODO change the header color to new gray rgb(15, 15, 15), and keep the transparency at the top
    <Container className={'flex justify-between items-center h-20'}>
      <div className={'overflow-hidden w-5 xs:w-full'}>
        <LogoDark className={'w-[120px] sm:w-[150px]'} />
      </div>
      <div className={'flex justify-end gap-4 items-center'}>
        <div className={'hidden xs:inline-flex sm:px-2 whitespace-nowrap font-semibold text-white'}>
          <a href="https://www.levelshealth.com/blog">Levels Blog</a>
        </div>
        <div className={'hidden xs:inline-flex sm:px-2 whitespace-nowrap font-semibold text-white'}>
          <a href="https://support.levelshealth.com/">Support</a>
        </div>
        <PrimaryButtonV2 href={`${process.env.NEXT_PUBLIC_APP_URL}/home/login`} size={'small'} variant={'tertiary'}>
          Log In
        </PrimaryButtonV2>
        <GetStartedButton size={'small'} className={'inline-flex'} />
      </div>
    </Container>
  );
};

const HomepageHeader = ({ shouldHide = true }: HomepageHeaderProps) => {
  const [scrollPos, setScrollPos] = useState(0);
  const isMobileAppWebview = useIsMobileAppWebview();
  // it doesn't need to be exactly at the top.
  const atTop = scrollPos <= 100;

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  if (isMobileAppWebview) return null;

  if (!shouldHide)
    return (
      <header className={cx('top-0 w-full z-10')}>
        <Section className="bg-black">{headerTemplate()}</Section>
      </header>
    );

  return (
    <>
      <style jsx>{`
        .header-background-color {
          background-color: ${atTop ? 'transparent' : 'rgb(15, 15, 15)'};
          z-index: 300;
        }
      `}</style>
      <header className={cx('header-background-color transition duration-500 fixed top-0 w-full')}>
        <Section className={cx('header-testing transition duration-500')}>{headerTemplate()}</Section>
      </header>
    </>
  );
};

export default HomepageHeader;

import Head from 'next/head';
import Script from 'next/script';

import { buildUrl } from 'util/loadContent';

export const metadata = {
  title: 'Levels - Unlock Your Metabolic Health',
  description: 'Levels helps you see how food affects your health with data from biosensors like continuous glucose monitors (CGMs)',
};

/**
 * @summary The <head> element for the homepage
 * @returns {JSX.Element}
 */

const HomepageHead = (): JSX.Element => {
  return (
    <>
      {/*
        CookiePro
        @see https://app.cookiepro.com/
      */}
      <Script type="text/javascript" src="https://cookie-cdn.cookiepro.com/consent/af040255-48fb-4045-9240-779686ddba90/OtAutoBlock.js" />
      <Script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="af040255-48fb-4045-9240-779686ddba90"
      />
      {/*
        Unbounce
        @see https://app.unbounce.com/
        Link to the specific popup
        @see https://app.unbounce.com/4918041/convertables/4b8a9971-65fb-4664-9cbf-48cdd03fe367
      */}
      <Script src="https://20f9d1588c884a5aaf0e7f3fe3b17711.js.ubembed.com" type="text/javascript" async />

      <Head>
        <title>{metadata.title}</title>
        <meta property="og:title" content={metadata.title} key="title" />
        <meta name={'description'} content={metadata.description} />
        <meta property="og:description" content={metadata.description} />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <link rel="canonical" href="https://www.levelshealth.com/" />
        {/*HEAD UP - This should be no be hardcoded*/}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.levelshealth.com/" />
        <meta property="og:site_name" content="Levels" />
        <meta property="article:publisher" content="https://www.facebook.com/LevelsHealth" />
        <meta property="article:modified_time" content="2023-04-05T14:10:31+00:00" />
        <meta property="og:image" content={buildUrl('levels-social-share.jpg')} />
        <meta property="og:image:width" content="2000" />
        <meta property="og:image:height" content="1400" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metadata.title} />
        <meta name="twitter:description" content={metadata.description} />
        <meta name="twitter:image" content={buildUrl('levels-social-share.jpg')} />
        <meta name="twitter:site" content="@Levels" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="1 minute" />
        <link rel="shortlink" href="https://www.levelshealth.com/" />
        <link
          rel="icon"
          href="https://www.levelshealth.com/wp-content/uploads/2023/09/cropped-LEVELS_Logo_Logomark_Green_576-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          href="https://www.levelshealth.com/wp-content/uploads/2023/09/cropped-LEVELS_Logo_Logomark_Green_576-192x192.png"
          sizes="192x192"
        />
        <link
          rel="apple-touch-icon"
          href="https://www.levelshealth.com/wp-content/uploads/2023/09/cropped-LEVELS_Logo_Logomark_Green_576-180x180.png"
        />
        <meta
          name="msapplication-TileImage"
          content="https://www.levelshealth.com/wp-content/uploads/2023/09/cropped-LEVELS_Logo_Logomark_Green_576-270x270.png"
        />
      </Head>
    </>
  );
};

export default HomepageHead;

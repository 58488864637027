import LogoDark from 'public/assets/logo-dark.svg';

import { Container } from 'components/homepage';
import { Footer, FooterLinkProps } from 'types';
import loadContent from 'util/loadContent';
import useIsMobileAppWebview from '../../hooks/useIsMobileAppWebview';

const FooterLink = (props: FooterLinkProps) => {
  const { children, className, href, name } = props;
  return (
    <a aria-label={name} className={className} data-location="external" href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  );
};

const HomepageFooter = () => {
  const isMobileAppWebview = useIsMobileAppWebview();

  if (isMobileAppWebview) return null;

  const { copyright, disclaimer, links, social } = loadContent('Footer') as Footer;

  return (
    <footer className="bg-black-title py-10 text-gray text-sm">
      <Container>
        <div className="xl:grid xl:grid-cols-3">
          <div className="px-6">
            <LogoDark className={'mb-10 w-[120px] sm:w-[150px]'} />
          </div>

          {/* Links */}
          <section className="px-6 mb-10">
            <ul className="grid grid-cols-2">
              {links.map((item, index) => (
                <li className="mb-2" key={index}>
                  <FooterLink {...item}>{item.name}</FooterLink>
                </li>
              ))}
            </ul>
          </section>

          {/* Social */}
          <section className="px-6 mb-10">
            <ul className="flex justify-start">
              {social.map((item, index) => {
                const { image: Image } = item;
                return (
                  <li className="mr-5" key={index}>
                    <FooterLink {...item}>
                      {/* @ts-ignore */}
                      <Image fill="white" />
                    </FooterLink>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      </Container>

      <hr className="my-10 text-disabled-dark" />
      <Container>
        {/* Copyright & Disclaimer */}
        <section className="px-6">
          <p className="mb-5">{copyright}</p>
          <div className="xl:grid xl:grid-cols-1">
            <p className="mb-5">{disclaimer[0]}</p>
            <p className="mb-5">{disclaimer[1]}</p>
          </div>
        </section>
      </Container>
    </footer>
  );
};

export default HomepageFooter;

/** US MONTLY SIGNUP STEPS TYPES */
export enum UsMonthlySignupSteps {
  AccessGateB2 = 'AccessGateB2',
  SelectCgmB2 = 'SelectCgmB2',
  ContactAndShippingB2 = 'ContactAndShippingB2',
  CheckoutB2 = 'CheckoutB2',
  // password setting is handled on its own sub-page instead of as a step. See `us/signup/monthly/[confirm_cart_public_handle].tsx`
}

/** US SIGNUP STEPS TYPES */
export enum UsSignupSteps {
  WhatsIncluded = 'WhatsIncluded',
  SetSubscription = 'SetSubscription',
  StudyEligibility = 'StudyEligibility',
  ResearchStudyConsent = 'ResearchStudyConsent',
  ContactAndShipping = 'ContactAndShipping',
  Checkout = 'Checkout',
  // password setting is handled on its own sub-page instead of as a step. See `us/signup/[confirm_cart_public_handle].tsx`
}

/** US SIGNUP S2 STEPS TYPES */
export enum UsSignupS2Steps {
  HowItWorksS2 = 'HowItWorksS2',
  SelectCGMS2 = 'SelectCGMS2',
  StudyEligibilityS2 = 'StudyEligibilityS2',
  ContactAndShippingS2 = 'ContactAndShippingS2',
}

/** US SIGNUP CONFIRM STEPS TYPES */
export enum UsSignupConfirmSteps {
  Password = 'Password',
  StudyEligibility = 'StudyEligibility',
  StudySignedConsent = 'StudySignedConsent',
  OrderRefunded = 'OrderRefunded',
  Success = 'Success',
  Loading = 'Loading',
  Error = 'Error',
}
